import React from "react"
import Login from "../components/Auth/Login"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LoginPage = () => (
  <Layout>
    <SEO title="Главная" />
    <Login />
  </Layout>
)

export default LoginPage
