export const ADMIN_NUMBERS = [
  "+79109139515", // Евгений Колосницын
  "+79109105535", // Дима Сэу
  "+79109139515", // Денис Щеголев
  "+79996495002", // Ваня
  "+79150300277 ", // Андрей
  "+79109184419", // Давид
  "+79023915132", // Андрей К
  "+79533393051", // Андрей Пушкарев
]
