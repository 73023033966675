import React, { useState } from "react"
import { navigate } from "gatsby"

import { Transition } from "@headlessui/react"
import { ADMIN_NUMBERS } from "./constants"

import { useRecoilState } from "recoil"
import { userState } from "../../store"

export default function Login() {
  const [, setUser] = useRecoilState(userState)

  const countryCode = "+7"
  const [phoneNumber, setPhoneNumber] = useState(countryCode)
  const [expandForm, setExpandForm] = useState(false)

  const [OTP, setOTP] = useState("")

  const [error, setError] = useState("")

  const requestOTP = () => {
    if (phoneNumber.length === 12 || phoneNumber.length === 11) {
      if (ADMIN_NUMBERS.includes(phoneNumber)) {
        setExpandForm(true)
      } else {
        setError("Доступ запрещен")
      }
    }
  }

  const verifyOTP = e => {
    let otp = e.target.value
    setOTP(otp)

    if (otp.length >= 6) {
      if (otp === "112233") {
        setUser({ phone: phoneNumber, isLoggedIn: true })
        navigate("/")
      } else {
        setError("Неверный код")
      }
    }
  }

  return (
    <Transition
      appear={true}
      show={true}
      enter="transition-opacity duration-700"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-700"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <section
        id="login"
        aria-labelledby="login-heading"
        className="mx-auto max-w-xl py-6 px-0 sm:py-24 md:px-12 lg:max-w-7xl"
      >
        <h1
          id="login-heading"
          className="text-center text-lg font-extrabold md:text-3xl"
        >
          Вход на сайт
        </h1>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Номер телефона
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="phone-number"
                    id="phone-number"
                    autoComplete="tel"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    disabled={expandForm}
                    required
                    placeholder="+71234567890"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              {error && <div className="text-red-500">{error}</div>}
              {expandForm ? (
                <>
                  <div>
                    <label
                      htmlFor="otpInput"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Введите код из сообщения
                    </label>
                    <div className="mt-1">
                      <input
                        id="otpInput"
                        name="otpInput"
                        type="number"
                        value={OTP}
                        onChange={verifyOTP}
                        autoComplete="current-password"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </>
              ) : null}

              <div>
                <button
                  onClick={requestOTP}
                  disabled={expandForm}
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-400"
                >
                  Запросить код
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Transition>
  )
}
